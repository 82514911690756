<template>
	<div class="w-full h-full flex flex-col">
		<header class="flex-none p-12  bg-gray-darkest flex items-center">
			<div class="flex-grow">
				<h1 class="text-white">Super Admin</h1>
			</div>
			<div class="flex-none">
				<router-link :to="{ name: 'accounts'}">
					Go to normal user mode
				</router-link>
			</div>
		</header>
		
		<div class="flex-grow overflow-auto p-12">
			<div v-if="loading == 'fetchingListAccounts'">
				Henter alle Upsell kontoer og brukere...
			</div>
			<div v-else-if="accounts && accounts.length">
				<div class="flex mb-8">
					<div class="flex-grow">
						<h2>Accounts ({{accounts.length}})</h2>
						<a @click="showUsers = !showUsers" class="link">{{ !showUsers ? 'Vis' : 'Skjul'  }} brukerinformasjon</a>
					</div>
					<div class="flex-none space-x-4 flex items-start">
						<router-link 
							:to="{ name: 'accounts', query: { showNewAccount: true } }"
							class="button dark">
							
							Opprett ny Upsell-konto
						</router-link>
						
						<button 
							class="button dark"
							@click="fetchAccessInformation()">
							
							AccessInfo
						</button>
						
						<button 
							class="button submit"
							@click="listAccounts()">
							
							Oppdatér 
						</button>
					</div>
				</div>
				
				<table>
					<thead>
						<tr>
							<th><strong>Navn</strong></th>
							<th>Brukere</th>
							<th>Enter</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="a in accounts"
							:key="'account_'+a.accountId">
							
							<td>
								<a @click="onSetAccount( a )">{{a.name}}</a>
							</td>
							<td>
								<span v-if="!showUsers">
									{{ a.users.length }}
								</span>
								
								<table v-if="showUsers">
									<thead>
										<tr>
											<th>email</th>
											<th>status</th>
											<th>comment</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="aU in a.users"
											:key="'account_'+a.accountId+'_'+aU.email">
											
											<td class="w-100">
												{{ aU.email }}
											</td>
											<td class="w-64">
												{{ aU.status }}
											</td>
											<td class="w-64">
												{{ aU.comment }}
											</td>
										</tr>
									</tbody>
								</table>
							</td>
							<td>
								<a @click="onSetAccount( a )">Enter</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				No accounts...
			</div>
		</div>
		
		
		
		
		
		
		
		
		
		<Modal 
			v-if="showAccessInformation"
			@close="showAccessInformation = false">
			
			<header slot="header">
				Access Information
			</header>
			
			<div class="p-8 overflow-auto">
				<pre>{{accessInformation}}</pre>
			</div>
		</Modal>
		
		
		
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: null,
			accounts: null,
			showAccessInformation: false,
			accessInformation: null,
			showUsers: false,
		}
	},
	
	methods: {
		async listAccounts(){
			try {
				this.loading = 'fetchingListAccounts';
				
				const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/superadmin', {
					method: 'listAccounts'
				});
				
				this.accounts = response.data;
			} 
			catch (e) {
				console.error(e);
			}
			finally {
				this.loading = null;
			}
		},
		
		async onSetAccount( account ) {
			try {
				await this.$store.dispatch('setAccount', account);
				this.$router.push({ name: 'home', params: {accountId: account.accountId} });
			} 
			catch (e) {
				console.error(e);
			} 
		},
	
		async fetchAccessInformation(){
			try {
				this.loading = 'fetchingAccessInformation';
				
				const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/superadmin', {
					method: 'accessInformation'
				});
				
				this.accessInformation = response.data;
				this.showAccessInformation = true;
			} 
			catch (e) {
				console.error(e);
			}
			finally {
				this.loading = null;
			}
		},
		
	},
	
	async mounted(){
		await this.listAccounts();
	},
}
</script>


<style lang="scss" scoped>
	table {
		thead {
			@apply bg-beige;
		}
		tbody {
			tr {
				&:hover {
					td {
						@apply bg-beige;
						@apply text-black;
						
					}
				}
			}
		}
	}
</style>